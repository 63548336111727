import { CurrencyCode } from "@asmbl/shared/constants";
import { Currency, exchangeFromTo } from "@asmbl/shared/currency";
import { Money, add, divide, max, min, ratio, zero } from "@asmbl/shared/money";
import { getMonthsElapsed } from "@asmbl/shared/time";
import React from "react";
import { TreeNode } from "src/components/JobStructureSelect/TreeSelect";
import { GRAY_4 } from "src/theme";
import {
  CashCompType,
  CompUnit,
  CompValue,
  EmploymentStatus,
} from "../__generated__/graphql";
import { AddToPhaseIcon } from "../components/AssembleIcons/Brand/AddToPhaseIcon";
import { getBandPointCashEquivalent } from "./BandPoint";
import { getPayCashEquivalent, getSalaryCashComp } from "./CashCompensation";
import { CompRecommendation, getPayIncrease } from "./CompRecommendation";

export function isLeveled(employee: {
  employments: { positionId: number | null }[];
}): boolean {
  return (
    employee.employments.length > 0 &&
    employee.employments[0].positionId !== null
  );
}

export function shouldGetLeveled(employee: {
  employmentStatus: EmploymentStatus;
  activeEmployment: { id: number } | null;
}): boolean {
  return !isInactive(employee) && employee.activeEmployment !== null;
}

export function needsLeveling(employee: {
  employmentStatus: EmploymentStatus;
  activeEmployment: { id: number; positionId: number | null } | null;
}): boolean {
  return (
    shouldGetLeveled(employee) &&
    employee.activeEmployment !== null &&
    employee.activeEmployment.positionId === null
  );
}

export function isInactive(employee: {
  employmentStatus: EmploymentStatus;
}): boolean {
  return employee.employmentStatus === EmploymentStatus.INACTIVE;
}

/* Return the number of full months elapsed since the date */
export function getTenure(employee: {
  activeAt: string | Date | null;
}): number | undefined {
  if (employee.activeAt === null) {
    return undefined;
  }
  return getMonthsElapsed(employee.activeAt);
}

export type CashCompensation = {
  type: CashCompType;
  annualCashEquivalent: Money;
  hourlyCashEquivalent: Money;
  unit: CompUnit;
};

export type AdjustedCashBand = {
  name: string;
  bandPoints: {
    value: {
      annualRate: Money | null;
      hourlyRate: Money | null;
      currencyCode: CurrencyCode;
    };
  }[];
};

export function getCompaRatioNew(
  cash: CashCompensation[] | null,
  bands: AdjustedCashBand[] | null
): number | undefined {
  if (cash == null || bands == null) return undefined;
  const salary = getSalaryCashComp(cash);
  const salaryBand = bands.find((b) => b.name === "Salary");

  if (salary === null || salary === undefined || salaryBand === undefined)
    return undefined;
  const isHourly = salary.unit === CompUnit.HOURLY_CASH;
  const salaryCash = getPayCashEquivalent(salary);
  const bandPoints = salaryBand.bandPoints.map((bp) =>
    getBandPointCashEquivalent(
      bp,
      salary.annualCashEquivalent.currency,
      isHourly
    )
  );

  const maxVal = max(...bandPoints);
  const minVal = min(...bandPoints);

  if (
    maxVal === undefined ||
    minVal === undefined ||
    maxVal.value < 0 ||
    minVal.value < 0
  )
    return undefined;

  const midPoint = divide(add(maxVal, minVal), 2);

  // If the midpoint is 0, that means all the band points were 0. We can't
  // return a compa ratio in that instance.
  return midPoint.value <= 0 ? undefined : ratio(salaryCash, midPoint);
}

export function getNewRevisedSalary(
  draft: CompRecommendation | null,
  employee: { activeCashCompensation: CashCompensation[] | null },
  currencies: Map<CurrencyCode, Currency>,
  defaultCurrency: Currency
): Money | undefined {
  const currentSalary = getSalaryCashComp(employee.activeCashCompensation);

  if (!currentSalary) return undefined;

  const currentSalaryCurrency =
    currencies.get(currentSalary.annualCashEquivalent.currency) ??
    defaultCurrency;

  const items = [...(draft?.items.values() ?? [])];

  const salaryIncrease = getPayIncrease(items, defaultCurrency.code);
  const salaryIncreaseCurrency =
    currencies.get(salaryIncrease.currency) ?? defaultCurrency;

  const convertedSalaryIncrease = exchangeFromTo(
    salaryIncrease,
    salaryIncreaseCurrency,
    currentSalaryCurrency
  );

  return add(convertedSalaryIncrease, currentSalary.annualCashEquivalent);
}

export function getNewPay(
  employee: {
    activeCashCompensation: CashCompensation[] | null;
    compRecommendation: { latestSubmittedPayIncrease: CompValue } | null;
  },
  currencies: Map<CurrencyCode, Currency>,
  defaultCurrency: Currency
): Omit<CompValue, "__typename"> | undefined {
  const currentSalary = getSalaryCashComp(employee.activeCashCompensation);

  const { annualCashEquivalent, hourlyCashEquivalent, unitType } =
    employee.compRecommendation?.latestSubmittedPayIncrease ?? {};

  if (!currentSalary) return undefined;

  const salaryCurrency =
    currencies.get(currentSalary.annualCashEquivalent.currency) ??
    defaultCurrency;

  const salaryIncreaseCurrency =
    annualCashEquivalent?.currency != null
      ? currencies.get(annualCashEquivalent.currency) ?? salaryCurrency
      : salaryCurrency;

  const convertedAnnualIncrease = annualCashEquivalent
    ? exchangeFromTo(
        annualCashEquivalent,
        salaryIncreaseCurrency,
        salaryCurrency
      )
    : zero(salaryCurrency.code);

  const convertedHourlyIncrease = hourlyCashEquivalent
    ? exchangeFromTo(
        hourlyCashEquivalent,
        salaryIncreaseCurrency,
        salaryCurrency
      )
    : zero(salaryCurrency.code);

  return {
    annualCashEquivalent: add(
      convertedAnnualIncrease,
      currentSalary.annualCashEquivalent
    ),
    hourlyCashEquivalent: add(
      convertedHourlyIncrease,
      currentSalary.hourlyCashEquivalent
    ),
    unitType: unitType ?? CompUnit.CASH,
  };
}

export function getJobTitle(employee: {
  activeEmployment: {
    jobTitle: string | null;
  } | null;
}): string | undefined {
  return employee.activeEmployment?.jobTitle ?? undefined;
}

export function getDepartmentName(employee: {
  activeEmployment: {
    position: {
      ladder: {
        department: {
          name: string;
        };
      };
    } | null;
  } | null;
}): string | undefined {
  return employee.activeEmployment?.position?.ladder.department.name;
}

export function getLadderName(employee: {
  activeEmployment: {
    position: {
      ladder: {
        name: string;
      };
    } | null;
  } | null;
}): string | undefined {
  return employee.activeEmployment?.position?.ladder.name;
}

export function getPositionName(employee: {
  activeEmployment: {
    position: {
      name: string;
    } | null;
  } | null;
}): string | undefined {
  return employee.activeEmployment?.position?.name;
}

export function getLevel(employee: {
  activeEmployment: {
    position: {
      level: number;
    } | null;
  } | null;
}): number | undefined {
  return employee.activeEmployment?.position?.level;
}

export const canEmployeeReceiveInvite = (emp: {
  user: { id: number } | null;
  latestUserInvite: {
    deletedAt: GraphQL_DateTime | null;
    expiredAt: GraphQL_DateTime;
  } | null;
}) => {
  return (
    emp.user === null &&
    (emp.latestUserInvite === null ||
      emp.latestUserInvite.deletedAt !== null ||
      new Date(emp.latestUserInvite.expiredAt) < new Date())
  );
};

export const formatEmpData = (emp: {
  email: string;
  displayName: string;
  id: number;
}) => ({
  email: emp.email,
  name: emp.displayName,
  employeeId: emp.id,
});

export type EmployeeTreeNodeData = {
  id: number;
  name: string;
  jobTitle: string | null;
};

export function selectionToTree<
  T extends {
    id: number;
    displayName: string;
    managerId: number | null;
    activeEmployment: {
      jobTitle: string | null;
    } | null;
  },
>(organizationName: string, employees: T[]): TreeNode<EmployeeTreeNodeData> {
  const employeeMap = new Map<number, T>();
  employees.forEach((emp) => employeeMap.set(emp.id, emp));

  const syntheticRoot: TreeNode<EmployeeTreeNodeData> = {
    data: { id: 0, name: "-", jobTitle: null },
    category: organizationName,
    hint: React.createElement(React.Fragment, null),
    selected: false,
    inherited: false,
    indeterminate: false,
    children: [],
  };

  const createTreeNode = (employee: T): TreeNode<EmployeeTreeNodeData> => {
    const name = employee.displayName;
    const jobTitle = employee.activeEmployment?.jobTitle ?? null;

    return {
      data: {
        id: employee.id,
        name,
        jobTitle,
      },
      category: jobTitle ?? "-",
      hint: React.createElement(React.Fragment, null),
      selected: false,
      inherited: false,
      indeterminate: false,
      children: [],
      decorator: React.createElement(AddToPhaseIcon, { color: GRAY_4 }),
    };
  };

  const managerMap: Map<number | null, TreeNode<EmployeeTreeNodeData>[]> =
    new Map();

  employees.forEach((employee) => {
    const node = createTreeNode(employee);
    const managerId = employee.managerId;

    if (!managerMap.has(managerId)) {
      managerMap.set(managerId, []);
    }
    managerMap.get(managerId)?.push(node);
  });

  const buildTree = (
    parentId: number | null
  ): TreeNode<EmployeeTreeNodeData>[] => {
    const children: TreeNode<EmployeeTreeNodeData>[] =
      managerMap.get(parentId) || [];
    children.forEach((childNode) => {
      const subordinates = buildTree(childNode.data.id);
      if (subordinates.length > 0) {
        childNode.children = subordinates;
      }
    });

    return children;
  };

  syntheticRoot.children = buildTree(null);

  return syntheticRoot;
}
