import { CurrencyCode } from "@asmbl/shared/constants";
import { annualToHourly, hourlyToAnnual } from "@asmbl/shared/currency";
import { Money } from "@asmbl/shared/money";
import { makeStyles, TableCell, Tooltip } from "@material-ui/core";
import { useEffect, useState } from "react";
import { NumberFormatValues, SourceInfo } from "react-number-format";
import { CompUnit, RecItemInput } from "src/__generated__/graphql";
import { useCompStructure } from "src/components/CompStructureContext";
import { useCurrencies } from "../../CurrenciesContext";
import { CashInput, PercentInput } from "./ConfigurableTargetInputCell";
import { DogEarWithTooltip } from "./DogEarWithTooltip";

interface Props {
  onChange: (
    newSalary: Money | undefined,
    unitType: CompUnit | null
  ) => unknown;
  value: RecItemInput | undefined;
  basePay: { annualCashEquivalent: Money; hourlyCashEquivalent: Money } | null;
  payCurrencyCode: CurrencyCode | null;
  isHourly?: boolean;
  disabled?: boolean;
  hasUnpublishedChanges?: boolean;
}

const useStyles = makeStyles(() => ({
  inputCell: {
    position: "relative",
    "&$inputCell": {
      padding: 0,
    },
  },
  inputContainer: { display: "flex", justifyContent: "flex-end" },
}));

export function SalaryInputCell({
  value,
  basePay,
  payCurrencyCode,
  onChange,
  disabled = false,
  hasUnpublishedChanges = false,
  isHourly = false,
}: Props): JSX.Element {
  const classes = useStyles();
  const { defaultCurrencyCode } = useCurrencies();
  const { compStructure } = useCompStructure();
  const currency =
    basePay?.annualCashEquivalent.currency ??
    payCurrencyCode ??
    defaultCurrencyCode;
  const workingHoursPerYear = compStructure?.workingHoursPerYear ?? 0;
  const absolute =
    disabled || !value
      ? undefined
      : calculateItemCompValues(value, workingHoursPerYear);
  const [absoluteInput, setAbsoluteInput] = useState<number | undefined>(
    absolute?.annualCashEquivalent?.value
  );
  const [hourlyInput, setHourlyInput] = useState<number | undefined>(
    absolute?.hourlyCashEquivalent?.value
  );

  const [percentInput, setPercentInput] = useState<number | undefined>(
    absolute?.annualCashEquivalent !== undefined && basePay !== null
      ? calculatePercent(
          basePay.annualCashEquivalent,
          absolute.annualCashEquivalent.value
        )
      : undefined
  );

  // Handle situations where the value prop changes after the component mounts
  useEffect(() => {
    if (absolute) {
      setAbsoluteInput(absolute.annualCashEquivalent?.value);
      setHourlyInput(absolute.hourlyCashEquivalent?.value);
      setPercentInput(
        absolute.annualCashEquivalent !== undefined && basePay !== null
          ? calculatePercent(
              basePay.annualCashEquivalent,
              absolute.annualCashEquivalent.value
            )
          : undefined
      );
    }
  }, [absolute, absoluteInput, basePay]);

  const resetInputValues = () => {
    setAbsoluteInput(undefined);
    setPercentInput(undefined);
    setHourlyInput(undefined);
    onChange(undefined, null);
  };

  const handleAbsolute = (
    { floatValue }: NumberFormatValues,
    { source }: SourceInfo
  ) => {
    if (disabled) {
      resetInputValues();
    }
    if (source !== "event") {
      return;
    }

    if (floatValue === undefined) {
      resetInputValues();
    } else {
      setAbsoluteInput(floatValue);
      setPercentInput(
        basePay !== null
          ? calculatePercent(basePay.annualCashEquivalent, floatValue)
          : undefined
      );
      if (isHourly) {
        setHourlyInput(
          compStructure?.workingHoursPerYear
            ? convertToHourly(floatValue, compStructure.workingHoursPerYear)
            : undefined
        );
      }

      if (floatValue !== value?.recommendedCashValue?.value) {
        onChange({ value: floatValue, currency }, CompUnit.CASH);
      }
    }
  };

  const handleHourly = (
    { floatValue }: NumberFormatValues,
    { source }: SourceInfo
  ) => {
    if (disabled) {
      resetInputValues();
    }
    if (source !== "event") {
      return;
    }

    if (floatValue === undefined) {
      resetInputValues();
    } else {
      setHourlyInput(floatValue);
      if (workingHoursPerYear) {
        setAbsoluteInput(floatValue * workingHoursPerYear);
        setPercentInput(
          basePay !== null
            ? calculatePercent(basePay.hourlyCashEquivalent, floatValue)
            : undefined
        );
      }
      if (floatValue !== value?.recommendedCashValue?.value) {
        onChange(
          {
            value: floatValue,
            currency,
          },
          CompUnit.HOURLY_CASH
        );
      }
    }
  };

  const handlePercent = (
    { floatValue }: NumberFormatValues,
    { source }: SourceInfo
  ) => {
    if (disabled) {
      resetInputValues();
    }
    if (source !== "event") {
      return;
    }

    if (floatValue === undefined) {
      resetInputValues();
    } else {
      setPercentInput(floatValue);

      const newAbsolute =
        basePay && basePay.annualCashEquivalent.value !== 0
          ? Math.round(basePay.annualCashEquivalent.value * (floatValue / 100))
          : 0;
      setAbsoluteInput(newAbsolute);
      setHourlyInput(newAbsolute / workingHoursPerYear);
      if (newAbsolute !== value?.recommendedCashValue?.value) {
        onChange({ value: newAbsolute, currency }, CompUnit.CASH);
      }
    }
  };

  const salaryCell = isHourly ? (
    <CashInput
      disabled={disabled}
      cashInput={hourlyInput}
      handleChange={handleHourly}
      currency={currency}
      isHourly={isHourly}
    />
  ) : (
    <CashInput
      disabled={disabled}
      cashInput={absoluteInput}
      handleChange={handleAbsolute}
      currency={currency}
    />
  );

  const secondCell = isHourly ? (
    <CashInput
      disabled={disabled}
      cashInput={absoluteInput}
      handleChange={handleAbsolute}
      currency={currency}
    />
  ) : (
    <PercentInput
      handleChange={handlePercent}
      disabled={disabled}
      percentInput={percentInput}
    />
  );
  return (
    <TableCell role="gridcell" className={classes.inputCell} colSpan={3}>
      {disabled ? (
        <Tooltip title="Please select a new position first" placement="top">
          <div className={classes.inputContainer}>
            {salaryCell}
            {secondCell}
          </div>
        </Tooltip>
      ) : (
        <div className={classes.inputContainer}>
          <>
            {hasUnpublishedChanges && <DogEarWithTooltip />}
            {salaryCell}
          </>
          {secondCell}
        </div>
      )}
    </TableCell>
  );
}

function convertToHourly(floatValue: number, workingHoursPerYear: number) {
  return Math.round((floatValue / workingHoursPerYear) * 10_000) / 10_000;
}

function calculatePercent(basePay: Money | null, absolute: number): number {
  return basePay && basePay.value !== 0
    ? Math.round((absolute / basePay.value) * 10000) / 100
    : 0;
}

function calculateItemCompValues(
  item: RecItemInput,
  workingHoursPerYear: number
): {
  annualCashEquivalent: Money | undefined;
  hourlyCashEquivalent: Money | undefined;
} {
  const { recommendedCashValue } = item;
  if (!recommendedCashValue) {
    return {
      annualCashEquivalent: undefined,
      hourlyCashEquivalent: undefined,
    };
  }
  return {
    hourlyCashEquivalent:
      item.unitType === CompUnit.HOURLY_CASH
        ? recommendedCashValue
        : {
            value: +annualToHourly(
              workingHoursPerYear,
              recommendedCashValue
            ).value.toFixed(2),
            currency: recommendedCashValue.currency,
          },
    annualCashEquivalent:
      item.unitType === CompUnit.HOURLY_CASH
        ? {
            value: Math.round(
              hourlyToAnnual(workingHoursPerYear, recommendedCashValue).value
            ),
            currency: recommendedCashValue.currency,
          }
        : recommendedCashValue,
  };
}
