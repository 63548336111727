import { gql } from "@apollo/client";
import { zero } from "@asmbl/shared/money";
import { Noun } from "@asmbl/shared/permissions";
import { makeStyles, Table, TableBody } from "@material-ui/core";
import { useCallback, useState } from "react";
import { useTrack } from "src/analytics";
import { useAuth } from "src/components/Auth/AuthContext";
import { useURLSearchParams } from "src/models/URLSearchParams";
import { useUpdateSingleEmployeePerfScore } from "src/mutations/CompCycle";
import {
  CompCycleSingleRecModal_compCycle as CompCycle,
  CompRecommendationInput,
  CurrencyCode,
  CompCycleSingleRecModal_participant as Employee,
  CompCycleSingleRec_perfRatingOption as PerfRatingOption,
  CompCycleSingleRecModal_position as Position,
  RecItemInput,
  CompCycleSingleRecModal_valuation as Valuation,
} from "../../../__generated__/graphql";
import {
  CompRecommendation,
  CompRecTableHeaders,
  getCashRecItems,
} from "../../../models/CompRecommendation";
import { getNewRevisedSalary } from "../../../models/Employee";
import { HeaderConfig } from "../../../models/HeaderConfig";
import { useEmplaceCompRecommendations } from "../../../mutations/CompRecommendation";
import { GRAY_6, WHITE } from "../../../theme";
import { useCurrencies } from "../../CurrenciesContext";
import { CompCycleBudgetBar2 } from "../CompCycleBudgetBar/CompCycleBudgetBar2";
import { CompCyclePersonRow } from "../CompTable/CompCyclePersonRow";
import { CompCycleGrouping } from "../types";
import {
  getSubmittedRecommendation,
  recommendationHasBeenTouched,
} from "../utils";
import { CompCycleSingleRecSubHeader } from "./CompCycleSingleRecSubHeader";
import { CompCycleTableHeaders } from "./CompCycleTableHeaders";

const useStyles = makeStyles(() => ({
  table: {
    overflow: "hidden",
    tableLayout: "fixed",
    borderTop: `1px solid ${GRAY_6}`,
  },
  barContainer: {
    display: "flex",
    flexDirection: "row",
  },
  rightBuffer: {
    display: "flex",
    flexDirection: "column",
    width: "5%",
    boxShadow: `0px -1px 0px ${GRAY_6}`,
    backgroundColor: WHITE,
  },
}));

interface Props {
  handleClose: () => unknown;
  compCycleId: number;
  employee: Employee;
  isIndirectReport: boolean;
  isPhaseConfigurationPeriod: boolean;
  positions: Position[];
  currentValuation: Valuation;
  compCycle: CompCycle;
  guidance?: number;
  perfRatingOptions: PerfRatingOption[];
  revisedPerfRating: string | null;
  setRevisedPerfRating: (perfRating: string | null) => unknown;
}

export function CompCycleSingleRecModal({
  handleClose,
  compCycleId,
  employee,
  isIndirectReport,
  isPhaseConfigurationPeriod,
  compCycle,
  positions,
  currentValuation,
  guidance,
  perfRatingOptions,
  revisedPerfRating,
  setRevisedPerfRating,
}: Props): JSX.Element {
  const classes = useStyles();
  const { trackEvent } = useTrack();

  const { permissions } = useAuth();
  const urlSearchParams = useURLSearchParams();
  const { currencies, defaultCurrency } = useCurrencies();
  const { user } = useAuth();

  const [budgetComponentFilter, setBudgetComponentFilter] =
    useState<CompCycleGrouping>("all");

  const managerId = urlSearchParams.get("manager");

  // if we are an HRBP then we will be submitting the request on behalf
  // of the manager we are filtering under
  const emplaceCompRecommendations = useEmplaceCompRecommendations(
    compCycleId,
    managerId != null && permissions.isHRBP()
      ? Number.parseInt(managerId)
      : null
  );

  const updateSingleEmployeePerfSore = useUpdateSingleEmployeePerfScore();
  const submitted =
    getSubmittedRecommendation({
      ...employee,
      id: employee.subjectId,
    }) ?? null;
  const [revisedRecommendation, setRevisedRecommendation] =
    useState<CompRecommendation | null>(submitted);

  const showOneTimeBonus = compCycle.allowBonus;

  const showEquityGrants = compCycle.allowEquity;

  const showSpacer = !showOneTimeBonus && !showEquityGrants;

  const userHasGlobalEditPermissions = permissions.canEditGlobal(
    Noun.CompRecommendation
  );

  // we are technically submitting and accepting the request that is made if
  // the employee being acted on is an indirect report of the user or if the
  // the current user has global edit permissions on comp recommendations
  const isSubmittingAndAccepting =
    isIndirectReport || userHasGlobalEditPermissions;

  // used to determine whether submit button is enabled during a phase
  // in cases where there is guidance or multiple reviewers
  const canUserEditInPhase =
    (compCycle.currentPhase != null && submitted?.canICurrentlyEdit) === true;

  const handleChangeRecommendation = useCallback(
    (_employeeId: number, newRecommendation: CompRecommendation | null) => {
      setRevisedRecommendation(newRecommendation);
    },
    []
  );

  const handleSave = async () => {
    const originalPerfRating = employee.perfRating;

    if (revisedPerfRating != null && revisedPerfRating !== originalPerfRating) {
      await updateSingleEmployeePerfSore({
        compCycleId,
        employeeId: employee.subjectId,
        perfRating: revisedPerfRating,
        note: `Changed from ${
          originalPerfRating ?? "nothing"
        } to ${revisedPerfRating} by ${user?.displayName ?? "unknown"}`,
      });
    }

    let recommendationResult;
    if (revisedRecommendation !== null) {
      recommendationResult = await emplaceCompRecommendations(
        revisedRecommendationToInput(
          revisedRecommendation,
          submitted,
          defaultCurrency.code,
          canUserEditInPhase
        )
      );

      trackEvent({
        object: "Change Requests",
        action: "Submitted",
        compCycleId,
      });
    }

    handleClose();

    return revisedRecommendation !== null
      ? recommendationResult !== null
      : true;
  };

  const newSalary = getNewRevisedSalary(
    revisedRecommendation,
    employee.subject,
    currencies,
    defaultCurrency
  );

  const headerConfig: HeaderConfig = {
    [CompRecTableHeaders.AVATAR]: { weight: 1, isVisible: true },
    [CompRecTableHeaders.NAME_POS]: { weight: 3, isVisible: true },
    [CompRecTableHeaders.DEPT_LADDER]: { weight: 3, isVisible: true },
    [CompRecTableHeaders.LEVEL]: { weight: 1, isVisible: true },
    [CompRecTableHeaders.PERF_RATING]: { weight: 3, isVisible: true },
    [CompRecTableHeaders.TENURE]: { weight: 2, isVisible: true },
    [CompRecTableHeaders.LOCATION]: { weight: 2, isVisible: true },
    [CompRecTableHeaders.MANAGER]: { weight: 1.5, isVisible: true },
    [CompRecTableHeaders.TOTAL_COMP]: { weight: 3, isVisible: true },
    [CompRecTableHeaders.BAND]: { weight: 3, isVisible: true },
    [CompRecTableHeaders.BONUS]: { weight: 2, isVisible: showOneTimeBonus },
    [CompRecTableHeaders.EQUITY]: { weight: 2, isVisible: showEquityGrants },
    [CompRecTableHeaders.SPACER]: { weight: 2, isVisible: showSpacer },
  };

  return (
    <>
      <CompCycleSingleRecSubHeader
        isSubmittingAndAccepting={
          !isPhaseConfigurationPeriod && isSubmittingAndAccepting
        }
        disableSaveButton={
          employee.perfRating === revisedPerfRating &&
          (revisedRecommendation === null ||
            !recommendationHasBeenTouched(
              revisedRecommendation,
              submitted,
              canUserEditInPhase
            ))
        }
        handleCancel={handleClose}
        handleSave={handleSave}
        compCycle={compCycle}
        filter={budgetComponentFilter}
        handleFilterChange={(filter: CompCycleGrouping) =>
          setBudgetComponentFilter(filter)
        }
      />
      <Table
        stickyHeader
        className={classes.table}
        role="treegrid"
        id="review-edit-rec-modal"
      >
        <CompCycleTableHeaders headerConfig={headerConfig} />
        <TableBody>
          <CompCyclePersonRow
            compCycle={compCycle}
            key={employee.subjectId}
            employee={employee}
            positions={positions}
            currentValuation={currentValuation}
            submittedRecommendation={submitted}
            revisedRecommendation={revisedRecommendation}
            onChangeRecommendation={handleChangeRecommendation}
            filter={budgetComponentFilter}
            headerConfig={headerConfig}
            guidance={guidance}
            perfRatingOptions={perfRatingOptions}
            setRevisedPerfRating={setRevisedPerfRating}
          />
        </TableBody>
      </Table>
      <div
        className={classes.barContainer}
        data-intercom-target={"request-modal"}
      >
        <CompCycleBudgetBar2
          prefixText={
            employee.managerCompCycleBudget !== null ? "Manager's" : " Org's"
          }
          filter={budgetComponentFilter}
          valuation={currentValuation}
          compCycle={compCycle}
          budget={employee.managerCompCycleBudget ?? null}
          recommendationItems={
            revisedRecommendation && newSalary
              ? getCashRecItems(
                  employee.subject,
                  Array.from(revisedRecommendation.items.values()),
                  newSalary
                )
              : []
          }
        />
        <div className={classes.rightBuffer} />
      </div>
    </>
  );
}

const recItem = gql`
  fragment CompCycleSingleRecModal_recItem on RecItem2 {
    id
    submittedAt
    recommendationType
    recommendedCashValue
    recommendedPercentValue
    recommendedTitle
    recommendedPosition {
      id
    }
    adjustedCashBands {
      id
      name
      bandPoints {
        value {
          ... on CashValue {
            annualRate
            currencyCode
          }
        }
      }
    }
    note
    unitType
  }
`;

CompCycleSingleRecModal.fragments = {
  participant: gql`
    ${CompCyclePersonRow.fragments.participant}
    ${CompCycleBudgetBar2.fragments.compCycleBudget}
    ${recItem}
    fragment CompCycleSingleRecModal_participant on CompCycleParticipant {
      subjectId
      compCycleId
      ...CompCyclePersonRow_participant
      managerCompCycleBudget {
        ...CompCycleBudgetBar2_compCycleBudget
      }
      perfRating
      compRecommendation(skipEligibility: $skipEligibility) {
        subjectId
        compCycleId
        reviewStatus
        canICurrentlyEdit(actingManagerEmployeeId: $actingManagerEmployeeId)
        latestSubmittedItems {
          ...CompCycleSingleRecModal_recItem
        }
      }
    }
  `,
  position: gql`
    ${CompCyclePersonRow.fragments.position}
    fragment CompCycleSingleRecModal_position on Position {
      ...CompCyclePersonRow_position
      id
      level
      type
      ladder {
        id
        name
      }
    }
  `,
  compCycle: gql`
    ${CompCycleBudgetBar2.fragments.compCycle}
    ${CompCyclePersonRow.fragments.compCycle}
    ${CompCycleSingleRecSubHeader.fragments.compCycle}
    fragment CompCycleSingleRecModal_compCycle on CompCycle2 {
      ...CompCyclePersonRow_compCycle
      ...CompCycleBudgetBar2_compCycle
      ...CompCycleSingleRecSubHeader_compCycle
      currentPhase {
        id
      }
    }
  `,
  valuation: gql`
    ${CompCycleBudgetBar2.fragments.valuation}
    ${CompCyclePersonRow.fragments.valuation}
    fragment CompCycleSingleRecModal_valuation on Valuation {
      ...CompCyclePersonRow_valuation
      ...CompCycleBudgetBar2_valuation
    }
  `,
};

function changeUndefinedValuesToZero(
  draftItem: RecItemInput,
  currency: CurrencyCode
): RecItemInput {
  return {
    ...draftItem,
    recommendedCashValue: draftItem.recommendedCashValue ?? zero(currency),
    recommendedPercentValue: draftItem.recommendedPercentValue ?? 0,
  };
}

function revisedRecommendationToInput(
  draft: CompRecommendation,
  submitted: CompRecommendation | null,
  defaultCurrency: CurrencyCode,
  canUserEditInPhase: boolean
): CompRecommendationInput[] {
  return recommendationHasBeenTouched(
    draft,
    submitted ?? undefined,
    canUserEditInPhase
  )
    ? [
        {
          subjectId: draft.subjectId,
          items: Array.from(draft.items.values()).map((draftItem) =>
            changeUndefinedValuesToZero(draftItem, defaultCurrency)
          ),
        },
      ]
    : [];
}
